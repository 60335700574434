<!-- 底部导航栏 -->
<template>
  <div id="FooterMenu">
    <ul>
      <li
        v-for="(item, index) in menuList"
        :key="index"
        @click="switchTab(item)"
      >
        <span class="iconfont icon-zhuye-xuanzhong" :class="item.icon"></span>
        <p>{{ item.name }}</p>
      </li>
      <!-- <li class="">
        <span class="iconfont icon-chongzhijiaofei"></span>
        <p>充值</p>
      </li>
      <li class="">
        <span class="iconfont icon-trend-fill"></span>
        <p>走势</p>
      </li>
      <li class="">
        <span class="iconfont icon-wode-wode"></span>
        <p>我的</p>
      </li> -->
    </ul>
  </div>
</template>

<script>
export default {
  name: "FooterMenu",
  data() {
    return {
      menuList: [
        {
          name: this.$t("m_tabbar.home"),
          icon: "icon-zhuye-xuanzhong",
          path: "/index",
        },
        {
          name: this.$t("m_tabbar.deposit"),
          icon: "icon-chongzhijiaofei",
          path: "/withdraw",
        },
        {
          name: this.$t("m_tabbar.trend"),
          icon: "icon-trend-fill",
          path: "/marketMovements",
        },
        {
          name: this.$t("m_tabbar.mime"),
          icon: "icon-wode-wode",
          path: "/user",
        },
      ],
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    switchTab(item) {
      this.$router.push(item.path);
      console.log("点击了");
    },
  },
};
</script>
<style lang="less" scoped>
#FooterMenu {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 1.3rem;
  ul {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 750px;
    background: linear-gradient(0deg, #ceab77 0%, #eddbc1 100%);
	
    li {
      flex: 1;
      text-align: center;
			margin-top: -10px;
      p {
        color: #8e7654;
        font-size: 0.24rem;
        padding-top: 0.05rem;
        text-align: center;
        word-wrap: break-word;
      }

      .iconfont {
        font-size: 0.46rem;
        color: #8e7654;
      }
    }

    .active {
      p {
        color: #fff;
      }

      .iconfont {
        color: #fff;
      }
    }
  }
}
/* @import url(); 引入css类 */
</style>