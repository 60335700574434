<!-- 移动端-充值 -->
<template>
  <div id="Recharge">
    <van-nav-bar
      :title="$t('m_tabbar.deposit')"
      @click-left="onClickLeft"
      @click-right="onClickRight"
    >
      <template #right>
        <i class="iconfont icon-kefu"></i>
      </template>
    </van-nav-bar>
		<div class="list" v-if="banklist.length > 0">
			<div class="bank"v-for="(item,index) in banklist" :key="index">
				<div class="bank_con">
					<div class="bank_name">{{item.bank_name}}</div>
					<div class="tt">
						<label>{{$t('header.jine')}}</label>
						<input type="type" v-model="item.money" />
					</div>
					<div class="ti" @click="pay(item)">{{$t('header.tx')}}</div>
				</div>
			</div>
		</div>
    <div id="NoData" v-if="banklist.length <= 0">
      <div class="no-data">
        <img
          src="../../../assets/img/notPage.png"
          :alt="$t('m_deposit.zwkysj')"
        />
        <p>{{$t('m_deposit.zwkysj')}}</p>
      </div>
    </div>
    <FooterMenu />
  </div>
</template>

<script>
import FooterMenu from "@/components/mc/FooterMenu";
import { bank_list,bank_pay } from "@/api/user";
import { Toast } from "vant";
export default {
  name: "",
  components: { FooterMenu },
  data() {
    return {
			bank_id:'',
			bank_name:'',
			money:'',
			banklist:[],
		};
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
		this.bank();
		// this.pay();
	},
  methods: {
    onClickLeft() {
      console.log("返回");
    },
    onClickRight() {
      this.$router.push('/cusService')
    },
		bank(){
			bank_list().then((res) => {
				console.log(res);
				this.bank_id = res.data[0].bank_id;
				this.bank_name = res.data[0].bank_name;
				// console.log(this.bank_id);
				// console.log(this.bank_name);
				// console.log(this.money);
				let result = res.data;
				let newList = [];
				result.forEach((item) => {
					item.money = '';
					newList.push(item);
				});
				this.banklist = newList
				console.log(this.banklist)
			}); 
		},
		pay(item){
			bank_pay({
				money:item.money,
				bank_id:item.bank_id,
			}).then((res) => {
				console.log(res);
				window.location.href = res.data.url;
			});
		}
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/mobile.less";
.list{
	width: 90%;
	display: flex;
	justify-content: center;
	margin-left: 5%;
	.bank_con{
		margin: 15px 0;
		.bank_name{
			color: #fff;
			font-size: 20px;
		}
		.tt{
			display: flex;
			align-items: center;
			width: 100%;
			margin: 10px 0;
			label{
				font-size: 20px;
				color: #fff;
				margin-right: 10px;
			}
			input{
				height: 20px;
				font-size: 15px;
			}
		}
		.ti{
			width: 80px;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #ffe324;
			border-radius: 10px;
		}
	}
}
#app {
  width: 100%;
}
#Recharge {
  width: 100%;
  height: 100%;
  background-color: #404040;
}
</style>